import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  first,
  takeUntil,
} from 'rxjs';
import {
  ActionType,
  CalculationData,
  FieldToUpdateElement,
  GridsterItemWidget,
  PageElement,
  Power,
  PowerAction,
  PowerEventType,
  Question,
  QuestionFieldIcon,
  QuestionFieldType,
  RelationshipAction,
  Station,
  StationFieldPrefix,
  StationLibraryRelationship,
  UpdateCreateContainerAction,
  UpdateRelationshipAction,
} from 'src/models';
import { PopupService } from 'src/app/core/popup.service';
import { v4 as uuidv4, validate as isUUID } from 'uuid';
import {
  ActionHelper,
  ComponentHelper,
  JsonValidator,
  TermsGeneric,
} from 'src/helpers';
import { StationService } from 'src/app/core/station.service';
import { ErrorService } from 'src/app/core/error.service';
import { FieldToUpdate } from 'src/models/field-to-update';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import _ from 'lodash';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { UtcDateFormatPipe } from 'src/helpers/pipes/utc-date-format/utc-date-format.pipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { AppendedActionItemComponent } from 'src/app/station/rules/actions/append-action/appended-action-item/appended-action-item.component';
import { UpdateRelationshipItemComponent } from 'src/app/station/rules/actions/update-relationship-action/update-relationship-item/update-relationship-item.component';
import { AdvancedUpdateActionItemComponent } from 'src/app/station/rules/actions/advanced-update-action/advanced-update-action-item/advanced-update-action-item.component';
import { CreateContainerActionComponent } from 'src/app/station/rules/actions/create-container-action/create-container-action.component';
import { AdvancedUpdateActionFormComponent } from 'src/app/station/rules/actions/advanced-update-action/advanced-update-action-form/advanced-update-action-form.component';
import { PowerService } from 'src/app/core/power.service';
import { AppendActionComponent } from 'src/app/station/rules/actions/append-action/append-action.component';
import { UpdateRelationshipFormComponent } from 'src/app/station/rules/actions/update-relationship-action/update-relationship-form/update-relationship-form.component';
import { UpdateFieldActionComponent } from 'src/app/station/rules/actions/update-field-action/update-field-action.component';
import { MatInputModule } from '@angular/material/input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CalculationActionComponent } from 'src/app/station/rules/actions/calculation-action/calculation-action.component';
import { MatSelectInfinityScrollDirective } from 'src/helpers/directives/mat-select-infinite-scroll/mat-select-infinite-scroll.directive';
/** Container action tab. */
@Component({
  selector: 'app-container-actions[actions][stationRithmId]',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatChipsModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatSelectSearchModule,
    AppendedActionItemComponent,
    UpdateRelationshipItemComponent,
    AdvancedUpdateActionFormComponent,
    AppendActionComponent,
    UpdateRelationshipFormComponent,
    UpdateFieldActionComponent,
    CreateContainerActionComponent,
    LoadingIndicatorComponent,
    AdvancedUpdateActionItemComponent,
    CalculationActionComponent,
    UtcDateFormatPipe,
    /** Directives. */
    MatSelectInfinityScrollDirective,
  ],
  templateUrl: './container-actions.component.html',
  styleUrls: ['./container-actions.component.scss'],
})
export class ContainerActionsComponent implements OnInit, OnDestroy {
  /** Component Helper. */
  componentHelper = ComponentHelper;

  /** Subject for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Subject for when the get of stations is consumed. */
  private destroyedPetition$ = new Subject<void>();

  /** Total actions. */
  @Input() totalActions = 0;

  /** The station id used to get shared values. */
  @Input() stationRithmId!: string;

  /** Actions of the current power. */
  @Input() actions: PowerAction[] = [];

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /** Feature flag for show old design/new design library tab.*/
  @Input({ required: true }) showRelationshipActions = false;

  /** Feature flag for show  Append Field actions.*/
  @Input({ required: true }) showAppendFieldActions = false;

  /** Feature flag for show action move container. */
  @Input({ required: true }) showActionMoveContainer = false;

  /** Feature flag for show advanced update action. */
  @Input({ required: true }) showAdvancedUpdateAction = false;

  /** Feature flag parent child relationship. */
  @Input({ required: true }) showParentChildRelationship = false;

  /** Show or not custom fields option. */
  @Input({ required: true }) showFlowedBy = false;

  /** Station Widgets array. */
  @Input() inputFrameWidgetItems: GridsterItemWidget[] = [];

  /** Whether to display only new creation action or not. */
  @Input() newCreationAction = false;

  /** Current rithmId of the rule. */
  @Input({ required: true }) ruleRithmId = '';

  /** Parent container Id to send if applicable. */
  parentContainer: Question = {
    rithmId: '',
    prompt: '',
    questionType: QuestionFieldType.ContainerName,
    isReadOnly: false,
    isRequired: false,
    isPrivate: false,
    possibleAnswers: [],
    children: [],
    enabled: true,
  };

  /** Bucket questions. */
  _bucketQuestions: Question[] = [];

  /** Bucket questions. */
  @Input() set bucketQuestions(bucket: Question[]) {
    this._bucketQuestions = bucket;
    this.getParentContainerIdFromBucket();
  }

  /**
   * Get bucket question.
   * @returns Bucket question data.
   */
  get bucketQuestions(): Question[] {
    return this._bucketQuestions;
  }

  /** This is a setter. It is called when the value of the input property changes. */
  @Input() set refreshIdContainer(value: string) {
    this._refreshIdContainer = value;
    this.addingAction = false;
  }

  /**
   * It returns the value of the private variable _refreshIdContainer.
   * @returns The value of the new id container.
   */
  get refreshIdContainer(): string {
    return this._refreshIdContainer;
  }

  /** Station Libraries.*/
  @Input() stationLibraryRelationships: StationLibraryRelationship[] = [];

  /** Station Libraries is Loading.*/
  @Input() stationLibrariesLoading = false;

  /** Feature flag to show the rules for Number field in conditions. */
  @Input() showNumberFieldRules = false;

  /** The modified/added action type to send back to flow. */
  @Output() actionsUpdEmitter = new EventEmitter<PowerAction[]>();

  /** Data from stations filtered to show autocomplete. */
  filteredStationsAutocomplete: Station[] = [];

  /** Type of actions. */
  actionTypesEnum = ActionType;

  /** Confirms whether the action is being edited. */
  editingAction = false;

  /** Whether is loading petition delete action. */
  deletingAction = false;

  /** The event type of the powerAction. */
  powerEventType = PowerEventType;

  /* A private variable that is used to store the value of the input property. */
  private _refreshIdContainer = '';

  /** Whether the user is adding a new container action. */
  addingAction = false;

  /** List of stations to display with the autocomplete. */
  stations: Station[] = [];

  /** List of stations to display with the autocomplete. */
  stationsFiltered: Station[] = [];

  /** Current Station object. */
  currentStation: Station | undefined = undefined;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Action type selector value. */
  actionTypeValue: ActionType | '' = '';

  /** Question Field Type. */
  questionFieldType = QuestionFieldType;

  /** Indicator when receiving questions from the selected station.*/
  sharedValuesLoading = false;

  /** Whether the station still loading. */
  stationsLoading = false;

  /** Whether the updated question has no value. */
  noValueOption = false;

  /** Action of type UpdateField to be edited. */
  actionToBeUpdated: PowerAction | null = null;

  /** Station Libraries is error.*/
  stationLibrariesError = false;

  /** Station questions is Loading.*/
  stationQuestionLoading = false;

  /** Station questions is error.*/
  stationQuestionError = false;

  /** Relations selected to work them.*/
  updateRelationshipAction: UpdateRelationshipAction = {
    relationships: [],
  };

  /**Actions to save  */
  actionsToSave: PowerAction[] = [];

  /**Relation selected in add relationship */
  relationsSelected: string[] = [];

  /**Parent container Id to send if applicable */
  parentContainerIds: Question[] = [];

  /**Parent container Id to send if applicable */
  parentContainerIdSelected = '';

  /** Powers after Adding new action. */
  powersReceived: Power[] = [];

  /** Index relation to edit. */
  indexRelationToEdit = -1;

  /** Whether you are saving the current action action . */
  savingAction = false;

  /** Update relationships enum. */
  updateRelationshipType:
    | ActionType.DeleteRelationship
    | ActionType.AddRelationship = ActionType.AddRelationship;

  /** Form move container. */
  moveContainerForm: FormGroup = new FormGroup({
    formStationsFilter: new FormControl(''),
    stations: new FormControl([], {
      nonNullable: true,
    }),
  });

  /** If editing  advance update section.*/
  isEditAdvanceUpdateAction = false;

  /** The paging for the request when getting the stations. */
  currentPageElement: PageElement = {
    pageNumber: 1,
    pageSize: 30,
    searchValue: '',
  };

  /** Whether all stations are loading or not. */
  loadingStations = false;

  /** The station ids backups to keep in the options. */
  stationIdsBackup: string[] = [];

  /** The selected stations to be shown on the chips. */
  stationsToShowInChips: string[] = [];

  /** The method was called by getting stations. */
  wasCalledByGetStations = false;

  constructor(
    private popupService: PopupService,
    private stationService: StationService,
    private errorService: ErrorService,
    private powerService: PowerService,
  ) {}

  /**
   * Returns container-actions.
   * @returns List of actions.
   */
  get getContainerActions(): PowerAction[] {
    return this.actions.filter(({ type }) =>
      ActionHelper.containerActions.includes(type),
    );
  }

  /**
   * Return True if the array has an action of type archive.
   * @returns True / False.
   */
  get hasArchiveAction(): boolean {
    const hasArchiveAction = this.actions.find(
      (action) => action.type === ActionType.ArchiveContainer,
    );
    return hasArchiveAction ? true : false;
  }

  /**
   * Return True if the array has an action of type archive.
   * @returns True / False.
   */
  get moveContainerToStation(): string[] {
    return this.moveContainerForm.value.stations;
  }

  /**
   * Check if the action is for update.
   * @returns If is update.
   */
  get areUpdateRelationshipTypes(): boolean {
    return (
      !!this.actionTypeValue &&
      [
        ActionType.UpdateRelationship,
        ActionType.AddRelationship,
        ActionType.DeleteRelationship,
      ].includes(this.actionTypeValue)
    );
  }

  /** Init Method. */
  ngOnInit(): void {
    this.getAllStations();
    this.listenAutocomplete$();
  }

  /**
   * Check if it is an action that can be editable.
   * @param action The action type.
   * @returns A true or false.
   */
  isEditableAction(action: ActionType): boolean {
    return [
      ActionType.UpdateField,
      ActionType.AddRelationship,
      ActionType.DeleteRelationship,
      ActionType.CreateContainer,
      ActionType.AppendField,
      ActionType.MoveContainer,
      ActionType.AdvancedUpdateField,
      ActionType.CalculateField,
    ].includes(action);
  }

  /**
   * Get all stations.
   */
  private getAllStations(): void {
    this.stationsLoading = true;
    this.stationService
      .getAllStationsOptimized()
      .pipe(first())
      .subscribe({
        next: (stations: Station[]) => {
          this.stations = stations.filter(
            (e) => e.rithmId !== this.stationRithmId,
          );
          this.currentStation = stations.find(
            (e) => e.rithmId === this.stationRithmId,
          );
          this.stationsLoading = false;
        },
        error: (error: unknown) => {
          this.stationsLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Saving action type changes.
   * @param powerToAdd The received power to add into the action object.
   */
  public saveActionType(powerToAdd: PowerAction | null = null): void {
    const pendingAction: PowerAction = powerToAdd || {
      order: this.totalActions + 1,
      rithmId: uuidv4(),
      type: ActionType.ArchiveContainer,
      target: '',
      data: '',
      resultMapping: '',
      header: '',
    };
    let actionIndex = -1;

    if (powerToAdd) {
      actionIndex = this.actions.findIndex(
        (action) => action.rithmId === powerToAdd.rithmId,
      );
    }
    if (this.orderOfOperations) {
      let eventAction: PowerEventType;
      if (actionIndex < 0) {
        powerToAdd && (powerToAdd.order = this.totalActions + 1);
        eventAction = PowerEventType.Add;
      } else {
        eventAction = PowerEventType.Update;
      }

      this.addOrUpdateSpecificAction(powerToAdd || pendingAction, eventAction);
    } else {
      if (actionIndex >= 0) {
        this.actions[actionIndex] = powerToAdd as PowerAction;
      } else {
        this.actions.push(pendingAction);
      }
    }

    if (!this.orderOfOperations) {
      this.addingAction = false;
      this.actionsUpdEmitter.emit(this.actions);
      this.actionTypeValue = '';
      this.actionToBeUpdated = null;
      this.isEditAdvanceUpdateAction = false;
    }
  }

  /**
   * Edit the selected action.
   * @param action Action to be edited.
   * @param index Action to edit.
   */
  public editContainerAction(action: PowerAction, index: number): void {
    this.editingAction = true;
    this.indexRelationToEdit = index;

    this.actionTypeValue = action.type;
    this.actionToBeUpdated = action;
    switch (action.type) {
      case ActionType.AddRelationship:
      case ActionType.DeleteRelationship:
        this.updateRelationshipType = action.type;
        this.actionsToSave = [action];
        break;
      case ActionType.CreateContainer:
        this.actionTypeValue = action.type;
        this.relationsSelected = this.getRelationshipTypes(action);
        break;
      case ActionType.MoveContainer:
        this.stationIdsBackup = action.target.split(',');
        this.moveContainerForm.controls.stations.setValue(
          this.stationIdsBackup,
        );
        this.stationsToShowInChips = this.stationIdsBackup;
        this.getOptimizedStations();
        break;
      case ActionType.AdvancedUpdateField:
        this.isEditAdvanceUpdateAction = true;
        break;
      default:
        break;
    }
    this.addingAction = true;
  }

  /**
   * Get relations to show.
   * @param action Update Relationships.
   * @returns Relations as array to show.
   */
  getRelationshipTypes(action: PowerAction): string[] {
    if (action.data) {
      const loadedRelations: string[] = [];
      const updateRelationshipAction = JSON.parse(
        action.data,
      ) as UpdateRelationshipAction;
      updateRelationshipAction.relationships.forEach((relation) => {
        const relationshipRithmId = relation.relationshipRithmId;
        this.stationLibraryRelationships.forEach((libraryRelation) => {
          libraryRelation.relationships.forEach((rela) => {
            if (rela.rithmId === relationshipRithmId) {
              if (relation.inward) {
                const relaTionToAdd =
                  rela.inwardName + '[' + rela.rithmId + ']_inwardName';
                loadedRelations.push(relaTionToAdd);
              }
              if (relation.outward) {
                const relaTionToAdd =
                  rela.outwardName + '[' + rela.rithmId + ']_outwardName';
                loadedRelations.push(relaTionToAdd);
              }
            }
          });
        });
      });
      return loadedRelations;
    } else {
      return [];
    }
  }

  /**
   * Remove archive action upon confirmation.
   * @param index Index action selected.
   */
  async removeContainerAction(index: number): Promise<void> {
    const confirm = await this.popupService.confirm({
      title: 'Are you sure?',
      message: `You will remove this ${TermsGeneric.Container.Single.toLowerCase()} action.`,
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (confirm) {
      this.actions.splice(index, 1);
      this.actionsUpdEmitter.emit(this.actions);
    }
  }

  /**
   * Confirm to remove action move container.
   */
  async confirmRemoveMoveContainerAction(): Promise<void> {
    const confirm = await this.popupService.confirm({
      title: 'Are you sure?',
      message: `You will remove this ${TermsGeneric.Container.Single.toLowerCase()} action.`,
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (confirm) {
      const index = this.actions.findIndex(
        (e) => e.rithmId === this.actionToBeUpdated?.rithmId,
      );
      const actionToRemove = this.actions.find(
        (e) => e.rithmId === this.actionToBeUpdated?.rithmId,
      );

      if (this.orderOfOperations && actionToRemove) {
        this.deleteSpecificAction(actionToRemove);
      } else {
        this.actions.splice(index, 1);
        this.actionsUpdEmitter.emit(this.actions);
      }
    }
  }

  /**
   * Stations that will have new containers.
   * @param action The Specific action.
   * @returns A string value.
   */
  createContainerIn(action: PowerAction): string | undefined {
    let stationString = '';
    if (action.type === ActionType.CreateContainer) {
      const stFound = this.stations.find((st) => st.rithmId === action.target);
      if (stFound) {
        stationString += stFound.name + '; ';
      }
    }
    return stationString;
  }

  /**
   * Get the data of the edited field.
   * @param action Current action to cast.
   * @returns A formatted field to update data.
   */
  getUpdateFieldData(action: PowerAction): FieldToUpdate {
    return JsonValidator.getObjectFromString<FieldToUpdate>(action.data);
  }

  /**
   * Get the calculation data of the action.
   * @param action The action to get the calculation data.
   * @returns The calculation data.
   */
  getCalculationData(action: PowerAction): CalculationData {
    return JsonValidator.getObjectFromString<CalculationData>(action.data);
  }

  /**
   * Cancel the process of creating a new action.
   */
  cancelAction(): void {
    this.addingAction = false;
    this.actionTypeValue = '';
    this.actionToBeUpdated = null;
    this.isEditAdvanceUpdateAction = false;
  }

  /**
   * Add actions to save.
   */
  addActionsToSave(): void {
    let parentId = '';
    if (this.updateRelationshipType === ActionType.AddRelationship) {
      parentId = this.parentContainer.rithmId;
    }
    this.updateRelationshipAction.rithmId = parentId;
    const power: PowerAction = {
      order: 1,
      data: JSON.stringify(this.updateRelationshipAction),
      resultMapping: '',
      type: this.updateRelationshipType,
      target: parentId,
      rithmId: this.stationRithmId,
      header: '',
    };
    this.actionsToSave = [power];
  }

  /**
   * Push or edit relationship.
   * @param relationshipAction Relationship Action.
   * @param isInwardName Is InwardName.
   * @param isOutwardName Is OutwardName.
   * @param deleting Is Deleting.
   */
  addUpdateRelationshipAction(
    relationshipAction: RelationshipAction,
    isInwardName = false,
    isOutwardName = false,
    deleting = false,
  ): void {
    const index = this.updateRelationshipAction.relationships.findIndex(
      (relation) =>
        relation.relationshipRithmId === relationshipAction.relationshipRithmId,
    );

    if (index !== -1) {
      const newRelation = {
        ...this.updateRelationshipAction.relationships[index],
      };

      if (deleting) {
        //Setting incoming property
        isInwardName && (newRelation.inward = false);
        isOutwardName && (newRelation.outward = false);
        //If both are false then delete full item
        if (!newRelation.inward && !newRelation.outward) {
          this.updateRelationshipAction.relationships.splice(index, 1);
        } else {
          this.updateRelationshipAction.relationships[index] = newRelation;
        }
      } else {
        isInwardName && (newRelation.inward = relationshipAction.inward);
        isOutwardName && (newRelation.outward = relationshipAction.outward);
        this.updateRelationshipAction.relationships[index] = newRelation;
      }
    } else {
      this.updateRelationshipAction.relationships.push(relationshipAction);
    }
  }

  /**
   * Get a clean label.
   * @param relation Relation label.
   * @returns Clean relation.
   */
  cleanRelationLabel(relation: string): string {
    return relation.split('[')[0];
  }

  /**
   * Get question for updateCreateContainerAction.
   * @param action Action which retrieve relations.
   * @returns Array question id.
   */
  getQuestionsRelationShip(action: PowerAction): string[] {
    const data =
      (
        JSON.parse(
          action.data || '{}',
        ) as unknown as UpdateCreateContainerAction
      ).questions || [];
    return data;
  }

  /**
   * Get target values.
   * @param action Action to get targets values.
   * @returns Array stations id.
   */
  getTargetValues(action: PowerAction): string[] {
    return action.target.split(',');
  }

  /**
   * Get station name by id.
   * @param rithmId Id to find.
   * @returns Station name.
   */
  getStationName(rithmId: string): string {
    const stations: Station[] = [...this.stations];
    this.currentStation && stations.unshift(this.currentStation);
    return stations.find((station) => station.rithmId === rithmId)?.name || '';
  }

  /**
   * Get prompt question bucket by id.
   * @param rithmId Id to find.
   * @returns Bucket prompt.
   */
  getQuestionPrompt(rithmId: string): string {
    const cleanId =
      typeof rithmId === 'string' &&
      rithmId.includes(StationFieldPrefix.CustomField)
        ? rithmId.replace(StationFieldPrefix.CustomField, '')
        : rithmId;
    if (!isUUID(cleanId)) return cleanId;
    const bucketByKey = _.keyBy(this.bucketQuestions, 'rithmId');
    return bucketByKey[cleanId]?.prompt || '';
  }

  /**
   * Get the icon of the question type by id.
   * @param rithmId The rithmId to find.
   * @returns The icon class.
   */
  getQuestionTypeIcon(rithmId: string): string {
    const cleanId =
      typeof rithmId === 'string' &&
      rithmId.includes(StationFieldPrefix.CustomField)
        ? rithmId.replace(StationFieldPrefix.CustomField, '')
        : rithmId;
    if (!isUUID(cleanId)) return '';
    // Use keyBy to create an object with rithmId as keys
    const bucketByKey = _.keyBy(this.bucketQuestions, 'rithmId');
    const currentQuestionType = bucketByKey[cleanId]?.questionType;
    return QuestionFieldIcon[currentQuestionType];
  }

  /**
   * Get parent container id.
   */
  getParentContainerIdFromBucket(): void {
    const parentId = this._bucketQuestions.find(
      (q) => q.questionType === QuestionFieldType.ParentContainerRithmId,
    );
    if (parentId) {
      this.parentContainer = parentId;
    }
  }

  /**
   * Close move container action.
   */
  closeMoveContainerAction(): void {
    this.actionTypeValue = '';
    this.addingAction = false;
    this.moveContainerForm.reset();
    this.actionToBeUpdated = null;
  }

  /**
   * Action move container.
   */
  actionMoveContainer(): void {
    const target = this.stationsToShowInChips.join(',');
    const index = this.actions.findIndex(
      (e) => e.rithmId === this.actionToBeUpdated?.rithmId,
    );
    const pendingAction: PowerAction = {
      order: this.actions[index]?.order || 1,
      rithmId: this.actions[index]?.rithmId || uuidv4(),
      type: ActionType.MoveContainer,
      target: target,
      data: '',
      resultMapping: '',
      header: '',
    };
    if (index > -1) {
      this.actions[index].target = target;
    } else {
      this.actions.push(pendingAction);
    }
    if (this.orderOfOperations) {
      let eventAction: PowerEventType;
      if (index < 0) {
        const newOrder = this.totalActions + 1;
        pendingAction.order = newOrder;
        eventAction = PowerEventType.Add;
      } else {
        eventAction = PowerEventType.Update;
      }
      this.addOrUpdateSpecificAction(pendingAction, eventAction);
    }
    if (!this.orderOfOperations) {
      this.closeMoveContainerAction();
      this.actionsUpdEmitter.emit(this.actions);
    }
  }

  /**
   * Remove the selected stations for action move container.
   * @param rithmId The id of the current station field to remove.
   */
  removeChipMoveContainer(rithmId: string): void {
    // Remove the id from stationsToShowInChips if it exists
    this.stationsToShowInChips = this.stationsToShowInChips.filter(
      (id) => id !== rithmId,
    );
    // Remove the id from stationIdsBackup if it exists.
    this.stationIdsBackup = this.stationIdsBackup.filter(
      (id) => id !== rithmId,
    );
    this.moveContainerForm.controls.stations.setValue(
      this.stationsToShowInChips,
    );
  }

  /** Listen changes in autocomplete. */
  private listenAutocomplete$(): void {
    this.moveContainerForm.controls.formStationsFilter.valueChanges
      .pipe(
        debounceTime(750),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
      )
      .subscribe((search) => {
        this.currentPageElement.searchValue = search || '';
        this.currentPageElement.pageNumber = 1;
        this.getOptimizedStations();
      });
  }

  /**
   * Get element setting.
   * @param element Element where it contains.
   * @returns Settings.
   */
  getDateSettings(element: FieldToUpdateElement): string {
    return (
      this.bucketQuestions.find((q) => q.rithmId === element.value)?.settings ||
      ''
    );
  }

  /**
   * Returns values from the assigned to field.
   * @param updateField Receives the field to update.
   * @returns A string.
   */
  getAssignedValues(updateField: FieldToUpdate): string {
    let updatedValue = '';
    if (
      updateField.target.text === 'assigned to' &&
      !updateField.source.text &&
      updateField.target.questionType === QuestionFieldType.AssignedUser
    ) {
      updatedValue = 'unassigned';
    }

    if (updateField.source.questionType === QuestionFieldType.FlowedBy) {
      updatedValue = QuestionFieldType.FlowedBy;
    }

    if (updateField.source.questionType === QuestionFieldType.CreatedBy) {
      updatedValue = QuestionFieldType.CreatedBy;
    }

    if (
      ![
        QuestionFieldType.TimeInStation,
        QuestionFieldType.DateTime,
        QuestionFieldType.Date,
        QuestionFieldType.CreatedBy,
      ].includes(updateField.source.questionType) &&
      updateField.source.text
    ) {
      updatedValue = updateField.source.text;
    }
    if (
      ComponentHelper.selectionTypeQuestions.includes(
        updateField.source.questionType,
      )
    ) {
      updatedValue = updatedValue.replace(/~/g, ',');
    }

    return updatedValue;
  }

  /**
   * Add or update action.
   * @param powerAction The current action to be created or updated.
   * @param powerEvent The current action to be created or updated.
   */
  addOrUpdateSpecificAction(
    powerAction: PowerAction,
    powerEvent: PowerEventType,
  ): void {
    if (powerEvent === PowerEventType.Duplicate) {
      powerAction.order = this.totalActions + 1;
      powerAction = {
        ...powerAction,
        rithmId: uuidv4(),
      };
    }
    this.savingAction = true;
    this.powerService
      .addOrUpdateSpecificAction(this.ruleRithmId, powerAction)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: powerEvent,
            action: powerAction,
            powerRithmId: this.ruleRithmId,
          });
          this.savingAction = false;
          this.cancelAction();
        },
        error: () => {
          this.savingAction = false;
          this.popupService.notify('Error saving container action', true);
        },
      });
  }

  /**
   * Popup confirmation delete/update action.
   * @param action Action of the action to be deleted.
   */
  async confirmRemoveAction(action: PowerAction): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: 'This Action will be deleted',
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      important: true,
    });
    if (response) {
      if (this.orderOfOperations) {
        this.deleteSpecificAction(action);
      } else {
        const actionIndex = this.actions.findIndex(
          (currentAction) => currentAction.rithmId === action.rithmId,
        );
        this.actions.splice(actionIndex, 1);
        this.actionsUpdEmitter.emit(this.actions);
      }
    }
  }

  /**
   * Get the stations for the autocomplete options in an efficient way.
   * @param allStations Whether it is an all-station load or not.
   */
  getOptimizedStations(allStations = true): void {
    if (allStations) {
      this.loadingStations = true;
    } else {
      this.currentPageElement.loading = true;
    }
    this.destroyedPetition$.next();
    this.stationService
      .getAllStationsOptimized(
        this.currentPageElement.searchValue,
        this.currentPageElement.pageNumber,
        this.currentPageElement.pageSize,
      )
      .pipe(takeUntil(this.destroyedPetition$.asObservable()))
      .subscribe({
        next: (stations: Station[]) => {
          this.filteredStationsAutocomplete = (
            this.currentPageElement.pageNumber > 1
              ? // We keep a single list in case it has been added before.
                _.uniqBy(
                  this.filteredStationsAutocomplete.concat(stations),
                  'rithmId',
                )
              : stations
          ).filter((e) => e.rithmId !== this.stationRithmId);
          this.currentPageElement.loadMoreElements =
            stations.length >= this.currentPageElement.pageSize;
          // If currently has id's in the backups.
          if (this.stationIdsBackup.length) {
            // Filter the stations and update the value in the form.
            const filteredStations = _.filter(
              this.filteredStationsAutocomplete,
              (station) => _.includes(this.stationIdsBackup, station.rithmId),
            ).map((station) => station.rithmId);
            this.moveContainerForm.controls.stations.setValue(filteredStations);
          } else {
            const currentStations = _.filter(this.stations, (station) =>
              _.includes(this.stationsToShowInChips, station.rithmId),
            ).map((station) => station.rithmId);
            // We set up the chip stations for you.
            this.moveContainerForm.controls.stations.setValue(currentStations);
          }
          if (allStations) {
            this.loadingStations = false;
          } else {
            this.currentPageElement.loading = false;
          }
        },
        error: (error: unknown) => {
          if (allStations) {
            this.loadingStations = false;
          } else {
            this.currentPageElement.loading = false;
          }
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Delete a specific action.
   * @param action  Delete the current action.
   */
  deleteSpecificAction(action: PowerAction): void {
    this.deletingAction = true;
    this.powerService
      .deleteAction(this.ruleRithmId, action.rithmId)
      .pipe(first())
      .subscribe({
        next: () => {
          this.powerService.setPowerAction({
            eventAction: PowerEventType.Delete,
            action,
            powerRithmId: this.ruleRithmId,
          });
          this.deletingAction = false;
        },
        error: () => {
          this.deletingAction = false;
          this.popupService.notify('Error to delete action', true);
        },
      });
  }

  /** Infinity scroll for get more elements. */
  getMoreElements(): void {
    if (
      !this.currentPageElement.loading &&
      this.currentPageElement.loadMoreElements
    ) {
      this.currentPageElement.pageNumber++;
      this.getOptimizedStations(false);
    }
  }

  /**
   * Add or remove station from ids.
   * @param stationId The id of the clicked station.
   */
  addOrRemoveStation(stationId: string): void {
    // Remove the id from stationIdsBackup if it exists.
    this.stationIdsBackup = this.stationIdsBackup.filter(
      (id) => id !== stationId,
    );
    if (this.stationsToShowInChips.includes(stationId)) {
      // Remove the id from stationsToShowInChips if it exists.
      this.stationsToShowInChips = this.stationsToShowInChips.filter(
        (id) => id !== stationId,
      );
    } else {
      this.stationsToShowInChips.push(stationId);
    }
    this.moveContainerForm.controls.stations.setValue(
      this.stationsToShowInChips,
    );
  }

  /** Listen the action type, to get stations. */
  listenActionType(): void {
    if (
      this.actionTypeValue === ActionType.MoveContainer &&
      !this.wasCalledByGetStations
    ) {
      this.getOptimizedStations();
      this.wasCalledByGetStations = true;
    }
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.destroyedPetition$.next();
    this.destroyedPetition$.complete();
  }
}
