import { Component, Input, OnInit } from '@angular/core';
import { StationTerms } from 'src/helpers';
import {
  AppendActionData,
  PowerAction,
  Question,
  QuestionFieldIcon,
} from 'src/models';
import _ from 'lodash';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';

/** List a single item for each appended action item. */
@Component({
  selector: 'app-appended-action-item[appendedAction]',
  standalone: true,
  imports: [CommonModule, MatChipsModule],
  templateUrl: './appended-action-item.component.html',
  styleUrls: ['./appended-action-item.component.scss'],
})
export class AppendedActionItemComponent implements OnInit {
  /** Contains the action data for append field. */
  actionData!: AppendActionData;

  /** Contains the current displayed action. */
  action!: PowerAction;

  /** Feature flag order of operations. */
  @Input() orderOfOperations = false;

  /**
   * Get the Action data through the power received.
   */
  @Input() set appendedAction(_power: PowerAction) {
    this.action = _power;
    this.actionData = JSON.parse(_power.data) as AppendActionData;
  }

  /** Contains a list of questions to extract readable text. */
  @Input() questionList: Question[] = [];

  /** A prompt to show for assigned to field. */
  readonly assignedUserPrompt = 'Assigned User';

  /** Contains the field icon and prompt. */
  appendValues: {
    [key: string]: {
      /** Question prompt. */
      prompt: string;
      /** Question icon. */
      icon: string;
    };
  } = {};

  containerInfo: Question[] = _.map(
    StationTerms.ContainerInfoItemsAsQuestion,
    (question) =>
      _.update(question, 'rithmId', () => 'info:' + question.questionType),
  );

  /** Contains the base field to append information. */
  baseField!: Question;

  /** Contains the keys of the appendValues. */
  keysField: string[] = [];

  /** Contains the question field icon. */
  questionFieldIcon = QuestionFieldIcon;

  /** Initialize the component. */
  ngOnInit(): void {
    const field = this.questionList.find(
      (fieldList) => fieldList.rithmId === this.actionData.target,
    );
    field && (this.baseField = field);
    this.createObjectValues();
  }

  /**
   * Create the object values for the append field.
   */
  createObjectValues(): void {
    let question: {
      /** Question id. */
      rithmId: string;
      /** Question prompt.*/
      prompt: string;
      /** Question icon. */
      icon: string;
    } = {
      rithmId: '',
      prompt: '',
      icon: '',
    };
    this.actionData.source.values.forEach((value) => {
      if (value.includes('rid:')) {
        const rithmId = value.substring(4);
        const findQuestion = this.questionList.find(
          (q) => q.rithmId === rithmId,
        );

        if (findQuestion) {
          question = {
            rithmId: findQuestion.rithmId,
            prompt: findQuestion.prompt,
            icon: QuestionFieldIcon[findQuestion.questionType],
          };
        }
      } else if (value.includes('info:')) {
        const findQuestion = this.containerInfo.find(
          (q) => q.rithmId === value,
        );
        if (findQuestion) {
          question = {
            rithmId: findQuestion.rithmId,
            prompt: findQuestion.prompt,
            icon: QuestionFieldIcon[findQuestion.questionType],
          };
        }
      } else if (value.includes('assignedTo:')) {
        question = {
          rithmId: this.assignedUserPrompt,
          prompt: this.assignedUserPrompt,
          icon: '',
        };
      } else {
        if (value.trim().length) {
          question = {
            rithmId: value,
            prompt: value,
            icon: '',
          };
        } else {
          question = {
            rithmId: `"${value}"`,
            prompt: `"${value}"`,
            icon: '',
          };
        }
      }
      this.appendValues = {
        ...this.appendValues,
        [question.rithmId]: {
          prompt: question.prompt,
          icon: question.icon,
        },
      };
      this.keysField = Object.keys(this.appendValues);
    });
  }
}
